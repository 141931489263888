import React, { useState } from 'react';
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import { useForm, ValidationError } from '@formspree/react';

import './App.css';


function Layout(){

  const [navIsOpen, setNavIsOpen] = useState(false);

  return (
  <div className="App">
    <nav className="bg-white border-gray-200">
      <div
        class="max-w-screen-xl flex flex-wrap items-center justify-between m-auto p-4"
       >
        <a
          href="https://www.mrimatch.com/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src="/logo192.png"
            class="h-8"
            alt="MRIMatch Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap">
              MRIMatch Inc.
          </span>
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setNavIsOpen(!navIsOpen)}
        >
          <span class="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${navIsOpen ? '' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default"
        >
          <ul
            className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white"
          >
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                onClick={() => setNavIsOpen(false)}>Home</Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0" aria-current="page"
                onClick={() => setNavIsOpen(false)}>Contact</Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                onClick={() => setNavIsOpen(false)}>About</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <hr />
    <div className='flex flex-col h-screen'>
      <div className='flex-1 box-border'>
        <div className='flex relative box-border items-center w-full'>
          <div className='flex flex-col md:flex-row items-center m-auto w-11/12 max-w-4xl'>
            <Outlet />
          </div>
        </div>
      </div>
      <footer className="bg-white rounded-lg shadow">
        <div>© 2024 MRIMatch Inc.</div>
        </footer>
    </div>
      
    </div>
  );
}

function Home(){
  return (
  <>

  <div className='py-16 lg:py-32 px-4 max-w-lg md:w-3/5'>
  <h2 className="mb-4 text-4xl tracking-tight font-extrabold  text-gray-900">
    MRIMatch Inc.
  </h2>
    <p className="whitespace-pre-wrap">
        MRIMatch Inc. is a biomedical technology company.
        <br></br>
        <br></br>
        We are developing an AI-based search engine that allows clinicians 
        to more efficiently navigate brain MRIs stored in PACS.
        <br></br>
        <br></br>
        We believe unlocking the untapped potential of vast amounts of data
        stored in PACS can provide numerous benefits to patients and physicians.    

    </p>
  </div>
  <div className='max-w-lg md:w-2/5'>
    <img class="h-auto max-w-full" src="/logo.png" alt="mrimatch logo" />
  </div>

      </>
  )
}

function Contact() {
  const [state, handleSubmit] = useForm("xvoeadrl");
  if (state.succeeded) {
      return <p>Thanks for joining!</p>;
  }
  return (
   
    <div className="bg-white m-auto">
      <div className="py-16 lg:py-32 px-4 mx-auto max-w-screen-sm">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold  text-gray-900">Contact Us</h2>
      <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a question? Want to learn more? Need details about our Business plan? Let us know.</p>
   
      <form onSubmit={handleSubmit} className="space-y-4">
      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
        Full Name
      </label>
      <input
        id="name"
        type="name" 
        name="name"
        className='shadow-sm bg-gray-50 border border-gray-300 rounded-lg text-gray-900 text-sm block w-full p-2.5'
        required
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
        Email Address
      </label>
      <input
        id="email"
        type="email" 
        name="email"
        className='shadow-sm bg-gray-50 border border-gray-300 rounded-lg text-gray-900 text-sm block w-full p-2.5'
        required
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
       <div class="sm:col-span-2">
       <label for="message" class="block mb-2 text-sm font-medium text-gray-900">Your message</label>
        <textarea
          id="message"
          name="message"
          rows="6"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 shadow-sm border border-gray-300 rounded-lg"
          required
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />

       </div>

      <button
        type="submit"
        disabled={state.submitting}
        className="py-3 px-5 text-sm font-medium text-center border border-gray-300"
      >
        Submit
      </button>
    </form>
    </div>
    </div>
  );
}

function About(){
  return (
    <div className='py-16 lg:py-32 px-4 mx-auto max-w-screen-sm'>
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold  text-gray-900">About Us</h2>
      <p>MRIMatch was founded in 2023 by a team of medical doctors and 
        data scientists with years of experience in the field of AI for radiology.
        <br></br>
        <br></br>
        Our mission is to empower physicians to deliver the best care to patients with neurological disorders. 
      </p>
    </div>
  )
}

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact"  element={<Contact />} />
          <Route path="about"  element={<About />} />
        </Route>
      </Routes>
    </div>

  )
}

export default App;
